<template>
  <div class="my-base-info">
    <div class="my-base-info-left">
        <img class="my-base-info-avatar" src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng068dd605ef9cf7c4866281a28301ef607721b1e9a56a591a65f512afaa0f607a" alt="个人头像" >
        <div class="my-base-info-box">
            <p class="user-nickname">西虹市王多鱼</p>
            <div class="user-contact-info-box">
                <div class="user-wx-info-box">
                    <img class="user-wx-info-pic" src="../../../../public/images/home/icon_wx.png" alt="微信号">
                    <p class="user-wx-info-text">{{userContactData.wxNumber}}</p>
                </div>
                <div class="user-qq-info-box">
                    <img class="user-qq-info-pic" src="../../../../public/images/home/icon_qq.png" alt="qq号">
                    <p class="user-qq-info-text">{{userContactData.qqNumber}}</p>
                </div>
                <div class="user-ip-info-box">
                    <img class="user-ip-info-pic" src="../../../../public/images/home/my_center_ip_icon.png" alt="ip属地">
                    <p class="user-ip-info-text">IP属地：{{userContactData.ipZone}}</p>
                </div>
                <div class="user-divider-line"></div>
                <div class="user-last-login-time">
                    <p class="user-last-login-time-text">最近登录：{{userContactData.lastLoginTime}}</p>
                </div>
            </div>
            <div class="user-product-info-box">
                <ul>
                    <li>
                        <p class="user-product-item-number">8</p>
                        <p class="user-product-item-name">我的商品</p>
                    </li>
                    <li>
                        <p class="user-product-item-number">2</p>
                        <p class="user-product-item-name">我的订单</p>
                    </li>
                    <li>
                        <p class="user-product-item-number">21</p>
                        <p class="user-product-item-name">我的收藏</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div v-if="!isWithdrawMenu" class="my-base-info-right">
        <div class="my-base-info-cash-car-money">
            <p class="my-base-info-cash-car-money-number">282.75</p>
            <p class="my-base-info-cash-car-money-title">可提现金额</p>
        </div>
        <div class="my-base-info-cash-car-withdraw-btn">
            <p class="my-base-info-cash-car-withdraw-btn-text">立即提现</p>
        </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
    name:'MyBaseInfo',
    data(){
        return{
            userContactData:{
                wxNumber:"tbh666",
                qqNumber:"1225398754",
                ipZone:"广东",
                lastLoginTime:"2023-11-23 02:28:19"
            }
        }
    },
    computed:{
        ...mapState({
            isWithdrawMenu:state=>state.user.isWithdrawMenu
        })
    }

}
</script>

<style lang="less" scoped>
.my-base-info{
    margin: 0 auto;
    width: 1200px;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    .my-base-info-left{
        display: flex;
        .my-base-info-avatar{
            width: 150px;
            height: 150px;
            border-radius: 75px;
        }
        .my-base-info-box{
            height: 150px;
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .user-nickname{
                height: 22px;
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: bold;
                font-size: 22px;
                color: #222222;
                line-height: 22px;
                text-align: left;
                font-style: normal;
            }
            .user-contact-info-box{
                margin-top: 14px;
                display: flex;
                cursor: default;
                align-items: center;
                .user-wx-info-box  {
                    display: flex;
                    .user-wx-info-pic  {
                        width: 17px;
                        height: 14px;
                    }
                    .user-wx-info-text  {
                        margin-left: 8px;
                        height: 17px;
                        font-family: MicrosoftYaHei;
                        font-size: 13px;
                        color: #666666;
                        line-height: 17px;
                        text-align: left;
                        font-style: normal;
                    }
                }
                .user-qq-info-box{
                    margin-left: 30px;
                    display: flex;
                    .user-qq-info-pic{
                        width: 12px;
                        height: 14px;
                    }
                    .user-qq-info-text{
                        margin-left: 8px;
                        height: 17px;
                        font-family: MicrosoftYaHei;
                        font-size: 13px;
                        color: #666666;
                        line-height: 17px;
                        text-align: left;
                        font-style: normal;
                    }
                }
                .user-ip-info-box{
                    margin-left: 30px;
                    display: flex;
                    .user-ip-info-pic{
                        width: 14px;
                        height: 14px;
                    }
                    .user-ip-info-text{
                        margin-left: 8px;
                        height: 17px;
                        font-family: MicrosoftYaHei;
                        font-size: 13px;
                        color: #666666;
                        line-height: 17px;
                        text-align: left;
                        font-style: normal;
                    }
                }
                .user-divider-line{
                    margin-left: 10px;
                    width: 1px;
                    height: 14px;
                    background: #E6E6E6;
                }
                .user-last-login-time{
                    margin-left: 10px;
                    display: flex;
                    .user-last-login-time-text{
                        height: 17px;
                        font-family: MicrosoftYaHei;
                        font-size: 13px;
                        color: #666666;
                        line-height: 17px;
                        text-align: left;
                        font-style: normal;
                    }
                }
            }

            .user-product-info-box{
                margin-top: 25px;
                height: 48px;
                ul{
                    display: flex;
                    align-items: center;
                    height: 48px;
                    list-style-type: none;
                    li{
                        list-style: none;
                        margin-right: 50px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        cursor: default;
                        .user-product-item-number{
                            height: 27px;
                            font-family: MicrosoftYaHei, MicrosoftYaHei;
                            font-weight: bold;
                            font-size: 20px;
                            color: #000000;
                            line-height: 27px;
                            text-align: right;
                            font-style: normal;
                        }
                        .user-product-item-name{
                            margin-top: 5px;
                            height: 16px;
                            font-family: MicrosoftYaHei;
                            font-size: 12px;
                            color: #424242;
                            line-height: 16px;
                            text-align: right;
                            font-style: normal;
                        }
                    }
                }
            }
        }
    }
    .my-base-info-right{
        width: 280px;
        height: 150px;
        background: linear-gradient( 270deg, #E73443 0%, #FF934D 100%);
        box-shadow: 0px 3px 10px 0px rgba(240,34,51,0.3);
        border-radius: 12px;
        background-image: url(../../../../public/images/home/my_center_cash_car_bg.png);
        background-size: 100% 100%;
        position: relative;
        .my-base-info-cash-car-money{
            position: absolute;
            left: 20px;
            top: 20px;
            cursor: default;
            .my-base-info-cash-car-money-number{
                height: 30px;
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: bold;
                font-size: 30px;
                color: #FFFFFF;
                line-height: 30px;
                text-align: left;
                font-style: normal;
            }
            .my-base-info-cash-car-money-title{
                margin-top: 6px;
                height: 16px;
                font-family: MicrosoftYaHei;
                font-size: 12px;
                color: #FFFFFF;
                line-height: 16px;
                text-align: left;
                font-style: normal;
            }
        }
        .my-base-info-cash-car-withdraw-btn{
            position: absolute;
            right: 20px;
            bottom: 20px;
            width: 98px;
            height: 36px;
            background: #FFFFFF;
            border-radius: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .my-base-info-cash-car-withdraw-btn-text{
                height: 16px;
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: bold;
                font-size: 12px;
                color: #EF5546;
                line-height: 16px;
                text-align: left;
                font-style: normal;
            }
        }
    }
}
</style>